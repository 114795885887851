import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { mq } from 'helpers/stylehelpers';
import Navbar from './navbar';
import Textlink from './textlink';

/** Wrapper des Elements */
const Wrapper = styled.nav`
    display: flex;
    justify-content: center;
    /* height: 0; // damit kein Leerraum im Layout entsteht - Content sollte die Höhe auf 3em setzen */
    overflow: visible;
    position: relative;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;

    > ${Navbar} {
        display: flex;
        width: 100%;

        ${mq.medium`
            width: auto;
            display: inline-flex;
        `}
    }
/*
    + section {
        ${mq.mediumDown`
            padding-top: ${rem(50)};
        `};
    } */
`;

const StyledTextlink = styled(Textlink)`
    display: flex;
    align-items: center;
    height: 100%;
`;

/**
 * Generiert eine Navigationsleiste, die sich am oberen Rand der Seite einhängt
 * @param {string} prop.className  Optional: Klasse für erweitertest Styling
 * @param {array} prop.items Die einzelnen Links, Form siehe propTypes
 * @param {children} prop.children Die Contents, die angefahren werden können
 * @example <StickyNav items={[{text: 'Foo', to="#foo"}]} />
 */
class StickyNav extends Component {
    navbarRef = React.createRef();

    state = {
        activePageFragment: null,
    };

    /** Setzt Seitenbereich aktiv */
    setActivePageFragment = activePageFragment => {
        this.setState({
            activePageFragment,
        });
    };

    /**
     * Rendert die StickyNav
     */
    render() {
        const { className, items, children } = this.props;
        const { activePageFragment } = this.state;

        const navItems = items.map(item => (
            <Navbar.Item border key={item.id}>
                <Navbar.Link isActive={activePageFragment === item.id} as="span">
                    <StyledTextlink
                        to={item.to}
                        noDecoration
                        isActive={activePageFragment === item.id}
                        scrollOffset={30}
                    >
                        {item.text}
                    </StyledTextlink>
                </Navbar.Link>
            </Navbar.Item>
        ));
        return (
            <>
                <Wrapper className={className}>
                    <Navbar rounded={false} ref={this.navbarRef}>
                        {navItems}
                    </Navbar>
                </Wrapper>
                {children({
                    setActivePageFragment: this.setActivePageFragment,
                })}
            </>
        );
    }
}

StickyNav.propTypes = {
    className: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            to: PropTypes.string,
        })
    ).isRequired,
    children: PropTypes.func.isRequired,
};

StickyNav.defaultProps = {
    className: null,
};

export default StickyNav;
